<template>
    <div class="layout">
        <!-- 顶部标题 -->
        <div class="top">
            <div class="aa51">51</div>
            <div class="bottom">51风流</div>
        </div>

        <div class="module">
            51风流苹果轻量版 安装指引 
        </div>
        <div>
            <a href="/51.mobileconfig" >
                <button class="dedwapp">
                下载配置文件 
            </button>
            </a>
           
        </div>

        <!-- 模块1 -->
        <div class="module">
            <p>1. 点击上方“下载配置文件”下载描述文件。</p>
            <div>
                <img src="../assets/config1.png" width="100%">
            </div>
            <p>2.点击“允许”后，从手机桌面打开“设置”，进入设置后点击“已下载的描述文件”。</p>
            <div>
                <img src="../assets/config2.png" width="100%">
            </div>
            <p>3.点击右上角的“安装”，输入锁屏密码，再次点击右上角的“安装”即可！</p>
            <div>
                <img src="../assets/config3.png" width="100%">
            </div>

        </div> 
    </div>
</template>
  
<style>
.layout {
    /* 设置整体布局样式 */
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.top {
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: center;
    --tw-text-opacity: 1;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* 添加阴影效果 */
}

.top div {
    margin-right: 5px;
    font-size: 18px;
    font-weight: bold;

}

.aa51 {
    color: rgb(236 72 153 / var(--tw-text-opacity));
}

.bottom {
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    background-color: rgb(236 72 153 / var(--tw-text-opacity));
    color: #fff;
    margin: 5px;
    /* 设置下部模块整体内边距 */
    border: 1px solid transparent;
    /* 下部边框为黑色 */
    border-radius: 5px;
    /* 添加圆角效果 */

}

.title {
    /* 标题样式 */
}



.module {
    margin-top: 10px;
    /* 设置模块之间的垂直边距 */
    width: 100%;
    /* 设置模块宽度为100% */
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* 设置模块边框为黑色 */
    box-sizing: border-box;
    /* 边框宽度不会增加模块宽度 */
    background-color: #fff;
    /* 设置模块背景为透明色 */
    border-radius: 10px;
    /* 添加圆角效果 */
    padding: 10px;

}

.dedwapp {
    margin: 10px;
    color: #fff;
    border-color: #ec4899;
    background: #EC4899;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px #0000000b;
    padding: 10px;
}

.module p {
    text-align: left;
}
</style>
  
<script>
export default {
    // 在这里定义组件的逻辑
}
</script>
  