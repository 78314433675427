<template>
    <div class="layout">
      <!-- 顶部标题 -->
      <div class="top">
        <div class="aa51">51</div>
        <div class="bottom">51风流</div>
      </div>
      
     
        <!-- 模块1 -->
        <div class="module"> 
          <!-- 模块内容 -->
          <!-- 图片 -->
         <div>
            <img  src="../assets/appScreenShot.png" width="100%"> 
         </div>
         <!-- 下载图片 -->
         <div>
            <img src="../assets/anz.jpg" width="20%">
         </div>

         <div  >
            <button class="dedwapp" @click="downloadApp">
                Android APK 下载
            </button> 
         </div>
         <div style="margin-bottom:20px;">
            * 如果下载速度过慢，请耐心等待下载完成。
         </div>
  

        </div>
  
        <!-- 模块2 -->
        <div class="module">
            <div>
            <img src="../assets/pg.png" width="20%">
         </div>

         <div  >
            <button class="dedwapp"  @click="iosTg">
                iOS轻量版本
            </button> 
         </div>
         <div style="margin-bottom:20px;">
            * 推荐大陆用户使用，但是存在无法访问的情况，需要定期更新配置 。
         </div>
   
        </div>
  
        <!-- 模块3 -->
        <div class="module">
            <div>
            <img src="../assets/pg.png" width="20%">
         </div>

         <div  >
            <button class="dedwapp"  @click="iosTg">
                iOS轻量版本
            </button> 
         </div>
         <div style="margin-bottom:20px;">
            * 推荐大陆用户使用，但是存在无法访问的情况，需要定期更新配置 。 
         </div>
        </div>


        
        <!-- 模块4 -->
        <div class="module">
            <strong>Android手机如何安装？</strong>
            <p>1.下载前请先至设置，把安全性设定修改成允许“未知来源”的应用，不同手机可能设定不同，但是大同小异。</p>
            <p>2.点击上方安装下载APP，按照步骤安装即可。</p>
            <p>3.如有安装提示，请忽略继续安装即可。</p>
            <p>*华为手机无法下载，请用第三方浏览器进行访问。</p>

            <strong>iOS手机如何使用？</strong>
            <p>由于苹果系统的限制，我们暂时无法上架苹果应用，请苹果用户先使用我们的轻量版本，我们轻量版和网页版仍然有完全一致的体验。</p>
             <p>我们仍然探索其他办法，我们会在晚点的时候提供iOS原生版本，敬情期待。</p>
             <strong>安装有安全提示？</strong>
             <p>有部分手机和杀毒软件提示是恶意软件，因为APP包含成人内容，请大家相信51风流不会放恶意程序，本站有广告盈利，不会放置恶意程序，请在安装的时候忽略提示，放心使用。</p>
        </div>
     
    </div>
  </template>
  
  <style>
  .layout {
    /* 设置整体布局样式 */
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
  
  .top {
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: center;
    --tw-text-opacity: 1; 
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
  }
  .top div{
     margin-right: 5px; 
    font-size: 18px; 
    font-weight:bold;

  }

  .aa51{
    color: rgb(236 72 153 / var(--tw-text-opacity));
  }
  .bottom { 
   padding-left: 5px;
   padding-right: 5px;
    box-sizing: border-box; 
    background-color:rgb(236 72 153 / var(--tw-text-opacity)); 
    color: #fff;
     margin: 5px; /* 设置下部模块整体内边距 */
    border: 1px solid transparent; /* 下部边框为黑色 */
     border-radius: 5px; /* 添加圆角效果 */
  
}
  
  .title {
    /* 标题样式 */
  }
   
 
  
  .module{
  margin-top: 10px; /* 设置模块之间的垂直边距 */
  width: 100%; /* 设置模块宽度为100% */
  border: 1px solid rgba(0, 0, 0, 0.2); /* 设置模块边框为黑色 */
  box-sizing: border-box; /* 边框宽度不会增加模块宽度 */
  background-color:#fff; /* 设置模块背景为透明色 */
  border-radius: 10px; /* 添加圆角效果 */ 
  padding: 10px;
   
  }

  .dedwapp{
    margin: 10px;
    color: #fff;
    border-color: #ec4899;
    background: #EC4899;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px #0000000b;
    padding: 10px;
  }
  .module p{
    text-align:left;
  }
  </style>
  
  <script>
  import { ref } from 'vue'
  import router from '@/router/index'
  import { useRoute } from 'vue-router' 
  export default {
    name: 'indexView',
    setup() {
   
  
      const route = useRoute()
  
      const key = route.query.code;
  
      const iosTg = () => {
        router.push({ path: '/ios' })
      }
  
      const downloadApp = () => {
        if (key) {
          window.location.href = "/51.apk?code=" + key
        } else {
          window.location.href = "/51.apk"
        }
      }
  
   
 
  
      return {
        iosTg,
        downloadApp,
      
      }
    }
  
  
  
  }
  </script>